@use '@circutor/ui/scss/abstracts/_cds-vars' as *;
@use '@circutor/ui/scss/02-tools/_cds-mixins-typography.scss' as *;

.modal {
  &__content {
    & > p {
      @include cds-paragraph-s;
      color: var(--cds-color-text-subtle);
      padding-bottom: $cds-spacing-50;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: $cds-spacing-50;
  }
}

