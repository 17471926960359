@use '@circutor/ui/scss/abstracts/_cds-vars' as *;

.inputs-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}

.input {
  height: $cds-spacing-250;
}