@use '@circutor/ui/scss/abstracts/_cds-vars' as *;

.container {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: 800px;
  margin: auto;

  & > div {
    width: 100%;
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: $cds-spacing-50;
    margin: auto;
    justify-content: center;
  }
}

.list {
  height: calc(100% - 48px);

  & ul {
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: $cds-spacing-50;
    overflow: hidden;
    padding-bottom: $cds-spacing-50;
  }
}

.actions-wrapper {
  display: flex;

}

.link {
  text-decoration: underline;
  font-weight: bold;
  color: #333;
  font-size: 1rem;
}

.link:hover {
  color: #000;
}