.cdsToast {
  display: flex;
  align-items: center;
  padding: 1.5rem;
  justify-content: flex-start;
  gap: 1rem;

  &Success {
    background-color: #00b388;
  }

  &Error {
    background-color: #ff5d3f;
  }

  :global {
    .ant-notification-notice-content .ant-notification-notice-with-icon {
      color: white;
      display: flex;
      justify-content: flex-start;
      gap: 1rem;
      align-items: center;
    }

    .ant-notification-notice-message {
      color: white !important;
      font-size: 20px;
      line-height: 22px;
      margin: 0 36px !important;
    }

    .simplebar-placeholder {
      display: none;
    }
  }
}