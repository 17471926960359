@use '@circutor/ui/scss/abstracts/_cds-vars' as *;
@use '@circutor/ui/scss/02-tools/_cds-mixins-typography.scss' as *;


.container {
  display: flex;
  gap: $cds-spacing-50;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 $cds-spacing-50 $cds-spacing-50;
  color: var(--cds-color-text-subtle);

  & > .overview {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    min-width: 500px;
    overflow: hidden;
    text-overflow: ellipsis;

    & strong {
      @include cds-paragraph-s(bold);
    }

    & span {
      @include cds-paragraph-s;
      
    }
  }

  @media only screen and (min-width: 1025px) {
    padding: 0 0 $cds-spacing-50;
  }
}