@use '@circutor/ui/scss/abstracts/_cds-vars' as *;

.formContainer {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  margin-bottom: 40px;
  // overflow: scroll;

  :global {
    .ant-select-selector {
      border-radius: 4px;
    }
  }
}

.deleteUserContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.fullWidthItem {
  grid-column: 1 / -1;
}

.formDivider {
  align-items: flex-start;
  align-self: stretch;
  border-bottom: 1px solid black;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 700;
  gap: 8px;
  height: 28px;
  margin-bottom: 32px;
  width: 100%;
}

.formActions {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.cdsToast {
  display: flex;
  align-items: center;
  padding: 1.5rem;
  justify-content: flex-start;
  gap: 1rem;

  &Success {
    background-color: #00b388;
  }

  &Error {
    background-color: #ff5d3f;
  }

  :global {
    .ant-notification-notice-content .ant-notification-notice-with-icon {
      display: flex;
      justify-content: flex-start;
      gap: 1rem;
      align-items: center;
    }

    .ant-notification-notice-message {
      color: white !important;
      font-size: 20px;
      line-height: 22px;
      margin: 0 36px !important;
    }

    .simplebar-placeholder {
      display: none;
    }
  }
}

@media only screen and (min-width: 864px) {
  .formContainer {
    grid-template-columns: 1fr 1fr;
  }
}

.deleteUserFooter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: $cds-spacing-50;
}
