@use '@circutor/ui/scss/abstracts/_cds-vars' as *;

.modal {
  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: $cds-spacing-50;
  }
}

