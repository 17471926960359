@use '@circutor/ui/scss/abstracts/_cds-vars' as *;
@use '@circutor/ui/scss/02-tools/_cds-mixins-typography.scss' as *;

.modal {
  &__content {
    display: flex;
    flex-direction: column;
    row-gap: $cds-spacing-50;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: $cds-spacing-50;
  }
}

.dragger {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__icon {
    font-size: 4rem;

    &::before {
      width: 4rem;
      height: 4rem;
    }
  }

  &__text {
    @include cds-head-s();
  }
}
