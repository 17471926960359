@use '@circutor/ui/scss/abstracts/_cds-vars' as *;

.item {
  display: flex;
  gap: $cds-spacing-50;
  padding: $cds-spacing-50 0;
  align-items: center;
 

}

.name {
  overflow: hidden;
  text-overflow: ellipsis;
}

.checkbox {
  width: 100%;
  overflow: hidden;

  & > span:last-child {
    overflow: hidden;
  }
}