@use '@circutor/ui/scss/abstracts/_cds-vars' as *;

.container {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  margin: auto;
 
  & > div {
    width: 100%;
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: $cds-spacing-50;
    margin: auto;
  }
}

.actions-wrapper {
  display: flex;
  
}