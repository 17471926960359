
.menu {
  border-bottom: none;

  :global {
    .ant-menu-submenu {
      padding: 0px;
    }
    .ant-menu-sub {
      max-height: 260px !important;
      overflow-y: auto;
    }
  }
}

.user-list {

  & > ul {
    // todo: investigate how to avoid doing this to limit the height of the menu.
    max-height: 260px !important;
  }
}